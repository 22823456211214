<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold"
        >Program Categories</v-toolbar-title
      >

      <v-spacer />
      <v-btn class="ml-5" @click="showCategoryCreateModal = true">
        Create New Category
      </v-btn>
    </v-app-bar>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th width="20%" class="text-left">Categories</th>
            <th width="20%" class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="clickable"
            v-for="category in categories"
            :key="category.id"
          >
            <td>
              {{ category.label }}
            </td>
            <td>
              <v-btn class="mr-2" @click="openEditCategory(category)">
                <v-icon>{{ icons.edit }}</v-icon>
              </v-btn>
              <v-btn class="error" @click="openConfirmModal(category)">
                <v-icon>{{ icons.delete }}</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <CategoryForm
      :show="showCategoryCreateModal"
      :form="form"
      :loading="categoryLoading"
      @onCancel="closeFormModal()"
      @onSave="addCategory()"
    />

    <CategoryForm
      :show="showCategoryEditModal"
      :form="editCategoryValue"
      :loading="categoryLoading"
      :action="'update'"
      @onCancel="closeFormModal()"
      @onSave="editCategory()"
    />

    <ConfirmModal
      title="Delete Category"
      message="Do you want to remove this category?"
      v-model="showConfirmModal"
      :loading="categoryLoading"
      @cancel="showConfirmModal = false"
      @confirm="deleteCategory()"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiPencil, mdiTrashCan } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import CategoryForm from '@/components/program/CategoryForm'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'ProgramCategoriesPage',
  mixins: [ControlsMixin],
  components: {
    AppBarNavIcon,
    CategoryForm,
    ConfirmModal,
  },
  computed: {
    ...mapState('programCategory', {
      form: (state) => state.categoryForm,
      categoryLoading: (state) => state.categoryLoading,
      categories: (state) => state.categories,
      editCategoryValue: (state) => state.editCategoryValue,
    }),
  },
  data() {
    return {
      showCategoryCreateModal: false,
      showCategoryEditModal: false,
      icons: {
        edit: mdiPencil,
        delete: mdiTrashCan,
      },
      showConfirmModal: false,
      toBeDeletedCategory: null,
    }
  },
  mounted() {
    this.getProgramCategories()
  },
  methods: {
    ...mapActions({
      addProgramCategory: 'programCategory/addProgramCategory',
      getProgramCategories: 'programCategory/getProgramCategories',
      editProgramCategory: 'programCategory/editProgramCategory',
      removeProgramCategory: 'programCategory/removeProgramCategory',
    }),
    ...mapMutations({
      updateEditCategory: 'programCategory/updateEditCategory',
      resetCategoryFormError: 'programCategory/resetCategoryFormError',
    }),
    addCategory() {
      this.addProgramCategory(this.form).then(() => {
        if (!this.form.$hasError('label')) {
          this.showCategoryCreateModal = false
        }
      })
    },
    openEditCategory(category) {
      this.updateEditCategory(category)
      this.showCategoryEditModal = true
    },
    editCategory() {
      this.editProgramCategory(this.editCategoryValue).then(() => {
        if (!this.editCategoryValue.$hasError('label')) {
          this.showCategoryEditModal = false
        }
      })
    },
    deleteCategory() {
      this.removeProgramCategory(this.toBeDeletedCategory).then(() => {
        this.showConfirmModal = false
      })
    },
    closeFormModal() {
      this.showCategoryCreateModal = false
      this.showCategoryEditModal = false
      this.resetCategoryFormError()
    },
    openConfirmModal(category) {
      this.showConfirmModal = true
      this.toBeDeletedCategory = category
    },
  },
}
</script>
