<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >{{ capitalizeFirstLetter(action) }} Category</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-text-field
            v-model="form.label"
            label="Category Label"
            required
            outlined
            :error-messages="
              form.$getError('label') || form.$getError('sub_categories')
            "
          ></v-text-field>

          <ul class="pl-10">
            <li
              class="pl-3 pr-3"
              v-for="(sub_categories, subKey) in form.sub_categories"
              :key="subKey"
            >
              <v-row>
                <v-text-field
                  v-model="sub_categories.label"
                  label="Subcategory Label"
                  required
                  outlined
                  :error-messages="
                    form.$getError('sub_categories.' + subKey + '.label') ||
                    form.$getError(
                      'sub_categories.' + subKey + '.sub_sub_categories'
                    )
                  "
                ></v-text-field>

                <v-icon
                  class="ml-2 mt-5"
                  color="red"
                  medium
                  @click="setCategoryToDelete(sub_categories, { subKey })"
                  >{{ icons.delete }}</v-icon
                >
              </v-row>
              <ul>
                <li
                  class="pl-3"
                  v-for="(
                    sub_sub_categories, subSubKey
                  ) in sub_categories.sub_sub_categories"
                  :key="subSubKey"
                >
                  <v-row>
                    <v-text-field
                      :disabled="!sub_categories.label"
                      v-model="sub_sub_categories.label"
                      label="Sub-subcategory Label"
                      required
                      outlined
                      :error-messages="
                        sub_sub_categories.length - 1 != subSubKey &&
                        form.$getError(
                          'sub_categories.' +
                            subKey +
                            '.sub_sub_categories.' +
                            subSubKey +
                            '.label'
                        )
                      "
                      @keyup="addSubCSubTextField(subKey, subSubKey)"
                    ></v-text-field>

                    <v-icon
                      :class="{
                        'ml-2': true,
                        'mt-5': true,
                        hide:
                          subSubKey ==
                          sub_categories.sub_sub_categories.length - 1,
                      }"
                      color="red"
                      medium
                      @click="
                        setCategoryToDelete(sub_sub_categories, {
                          subKey,
                          subSubKey,
                        })
                      "
                      >{{ icons.delete }}</v-icon
                    >
                  </v-row>
                </li>
              </ul>
            </li>
          </ul>
          <v-btn color="primary" @click="addSubCategory">Add Subcategory</v-btn>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="formActions('cancel')">
          Cancel
        </v-btn>
        <v-btn color="primary" :loading="loading" @click="formActions('save')">
          {{ capitalizeFirstLetter(action) }} Category
        </v-btn>
      </v-card-actions>
    </v-card>

    <ConfirmModal
      title="Delete Category"
      message="Do you want to remove this category?"
      v-model="showConfirmModal"
      @cancel="showConfirmModal = false"
      @confirm="deleteCategory()"
    />
  </v-dialog>
</template>

<style>
.hide {
  visibility: hidden;
}
</style>
<script>
import { mapMutations, mapActions } from 'vuex'
import { mdiDelete } from '@mdi/js'
import ConfirmModal from '@/components/modals/ConfirmModal'
export default {
  components: {
    ConfirmModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: 'create',
    },
    form: {
      type: [Object, Array],
      require: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    icons: {
      delete: mdiDelete,
    },
    showConfirmModal: false,
    toBeDeletedCategory: null,
  }),
  methods: {
    ...mapActions({
      removeEducationCategory: 'educationCategory/removeEducationCategory',
    }),
    formActions(action) {
      if (action === 'cancel') {
        this.$emit('onCancel')
      } else {
        this.$emit('onSave')
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    ...mapMutations({
      addSubCategory: 'educationCategory/addSubCategory',
      addSubSubCategory: 'educationCategory/addSubSubCategory',
      deleteSubSubCategory: 'educationCategory/deleteSubSubCategory',
      deleteSubCategory: 'educationCategory/deleteSubCategory',
    }),
    addSubCSubTextField(subKey, subSubKey) {
      const subCategories = this.form.sub_categories[subKey]
      const subSubCategories = subCategories.sub_sub_categories
      const subSubCategoriesLastIndex = subSubCategories.length - 1
      if (subSubCategoriesLastIndex === subSubKey) {
        this.addSubSubCategory(subKey)
      }
    },
    deleteCategory() {
      this.removeEducationCategory(this.toBeDeletedCategory).then(() => {
        this.showConfirmModal = false
      })
    },
    setCategoryToDelete(category, subs) {
      if (category.id) {
        this.toBeDeletedCategory = category
        this.showConfirmModal = true
      } else {
        if (subs.subSubKey !== undefined) {
          this.deleteSubSubCategory(subs)
        } else {
          this.deleteSubCategory(subs.subKey)
        }
      }
    },
  },
}
</script>
