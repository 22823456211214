<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >{{ capitalizeFirstLetter(action) }} Category</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-text-field
            v-model="form.label"
            label="Category Label"
            required
            :error-messages="form.$getError('label')"
          ></v-text-field>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="formActions('cancel')">
          Cancel
        </v-btn>
        <v-btn color="primary" :loading="loading" @click="formActions('save')">
          {{ capitalizeFirstLetter(action) }} Category
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: 'create',
    },
    form: {
      type: [Object, Array],
      require: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formActions(action) {
      if (action === 'cancel') {
        this.$emit('onCancel')
      } else {
        this.$emit('onSave')
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
}
</script>
